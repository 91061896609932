import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import Layout from "../components/layout";

class RootIndex extends React.Component {
  render() {
    const cards = get(this, "props.data.allContentfulCard.edges");
    const hero = get(this, "props.data.allContentfulHero.edges");
    const siteTitle = get(this, "props.data.site.siteTitle.edges");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet title={siteTitle} />
          <Hero data={hero[0].node} />
          <div className="wrapper">
            {cards.map(({ node }) => {
              return (
                <div className="card my-3" key={node.title}>
                  <div className="card-body">
                    <h5 className="card-title">{node.title}</h5>
                    {node.headerImage && (
                      <img
                        src={node.headerImage.sizes.src}
                        class="img-fluid mx-3 mb-3 float-right"
                        style={{ maxHeight: "400px" }}
                      />
                    )}
                    <div
                      className="card-text contentful"
                      dangerouslySetInnerHTML={{
                        __html: node.body.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div className="card my-3">
              <div className="card-body">
                <h5 className="card-title">Previous Recordings</h5>
                <table className="table my-3">
                  <thead className="thead-light">
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Dec 21, 2020</td>
                      <td>
                        Lecture on karma and the 3 doors of body speech mind and how to purify karma.
                      </td>
                      <td>
                        <a
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/targye-karma-21-dec-2020.m4a"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Dec 14, 2020</td>
                      <td>
                        Lecture on karam and how they interact with the elements (earth, wind, water, fire, space)
                      </td>
                      <td>
                        <a
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/targye-karma-14-dec-2020.m4a"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Dec 7, 2020</td>
                      <td>
                        Lecture on the wheel of samsara, the three mind poisons and the 12 links of dependent origination.
                      </td>
                      <td>
                        <a
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/targye-karma-7-dec-2020.m4a"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Nov 30, 2020</td>
                      <td>
                        Lecture on dependent origination based on the{" "}
                        <a
                          target="_blank"
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/the-salistamba-sutra.pdf"
                        >
                          Rice Sutra text
                        </a>
                        , covering the first 4 links: ignorance, mental
                        formation, conciousness and name/form.
                      </td>
                      <td>
                        <a
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/targye-karma-30-nov-2020.m4a"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Nov 23, 2020</td>
                      <td>
                        Lecture on the varoius emotions that effect beings in
                        the different 6 realms and how to work with them and the
                        mind.
                      </td>
                      <td>
                        <a
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/targye-karma-23-nov-2020.m4a"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Nov 16, 2020</td>
                      <td>
                        Lecture on the 6 realms (God, Demi God, Human, Animal,
                        Hungry Ghost and Hell beings) and how each being has a
                        different set of perceptions.
                      </td>
                      <td>
                        <a
                          href="https://arsy-org.s3.eu-west-3.amazonaws.com/lamatargye/karma-2020/targye-karma-16-nov-2020.m4a"
                          target="_blank"
                        >
                          Download
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulHero(filter: { tag: { eq: "lamatargye-org:index:hero" } }) {
      edges {
        node {
          title
          backgroundImage {
            fluid {
              srcSet
            }
            description
          }
          tag
          lead {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    allContentfulCard(sort: { fields: updateOn, order: DESC }) {
      edges {
        node {
          title
          tag
          updateOn
          body {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          headerImage {
            sizes {
              src
            }
          }
        }
      }
    }
  }
`;
