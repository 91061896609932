import React from 'react'

export default ({ data }) => (
  <div className="jumbotron my-3">
    <h1 className="display-4" style={{ maxWidth: '400px' }}>
      {data.title}
    </h1>
    <div
      className="lead"
      dangerouslySetInnerHTML={{
        __html: data.lead.childMarkdownRemark.html,
      }}
      style={{ maxWidth: '400px' }}
    />
  </div>
)
